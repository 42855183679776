import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import tickerLogo from "../../assets/icons/footerIconLogo.png";
import eczodexLogo from "../../assets/icons/footer-main-logo.png"
import CtaItem from "./CtaItem";


const styles = {
  footer: `w-full h-fit min-h-[18.4rem] bg-[#FFF] gap-y-[2.5rem] flex flex-col items-center justify-center py-[1.25rem] lg:px-[10rem] lg:pb-[3.8rem]`,
  logoCtn: `w-[6.3rem] h-auto`,
  copyrightCtn: `text-[#82849E] text-base lg:text-[1.125rem] font-normal`,
  copyrightCtnSec: `text-[#82849E] text-base lg:text-[1.125rem] font-normal`,
  tickerCtn: `flex gap-[1.06rem] items-center justify-center`,
  tickerIcon: `w-[2.25rem] h-[2.25rem]`,
  tickerText: `tickerText`
};

interface IProps {
  scrollToSection: (urlFragment: string) => void;
}

const Footer = ({ scrollToSection }: IProps) => {
  const location = useLocation();
  const { pathname } = location;
  const [currentRoute, setCurrentRoute] = useState("");

  useEffect(() => {
    if (pathname === "/") {
      setCurrentRoute("landing");
    } else if (pathname === "/contact-us") {
      setCurrentRoute("contact-us");
    }
  }, [pathname]);

  return (
    <div className={styles.footer}>
              <CtaItem/>
      <div className="w-fit flex flex-col items-center justify-center gap-[1rem] lg:gap-[2.5rem]">
      <div className={styles.tickerCtn}>
          <img src={tickerLogo} alt="tickerLogo" className={styles.tickerIcon}/>
          <div className={styles.tickerText}>
            USDO
          </div>
        </div>
        <div className={styles.copyrightCtn}>Ⓒ All rights reserved 2024</div>
        <div className={styles.copyrightCtn}>USDO – Issued by Regulated Partners</div>

        <div className="flex items-center justify-center gap-[0.75rem]">
        <div className={styles.copyrightCtn}>Powered by</div>
        <img
          src={eczodexLogo}
          alt="main-logo"
          width={150}
          height={36}
          className={styles.logoCtn}
        />
        </div>
      </div>
    </div>
  );
};

export default Footer;