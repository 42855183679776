import React from "react";
import michaelQuartey from "../../../assets/profiles/MichaelQuartey.png";
import lukasBruell from "../../../assets/profiles/LukasBruell.png";

import linkedinLogo from "../../../assets/profiles/linkedin.svg";
import RectangleWhite from "../../../assets/images/RectangleWhite.svg";

const styles = {
  founders: `relative w-full h-fit min-h-[42rem] px-[1.25rem] bg-[#F6F8FA] flex flex-col items-center justify-start overflow-hidden`,
  founderContainer: `w-fit flex flex-col items-center pt-[3rem] gap-[3.5rem]`,
  heading: `text-[0.62rem] lg:text-base text-[#2B8AC8] font-semibold tracking-[0.1rem] uppercase`,
  profileContainer: `flex flex-wrap items-center justify-center gap-[1.69rem] mb-20 lg:mb-[5.62rem]`,
  profileItem: `w-[15.8rem] lg:w-[17.8rem] flex flex-col items-center justify-center`,
  profileCtn: `relative w-[8.45rem] lg:w-[10.45rem] h-[8.45rem] lg:h-[10.45rem]  overflow-hidden mb-[1.5rem] rounded-[50%]`,
  profileBg: `w-full h-full absolute top-0 left-0 z-0 bg-[url('./assets/profiles/profilebg.png')] bg-contain bg-no-repeat`,
  profileImage: `w-full h-full absolute top-0 left-0 z-10`,
  profileInfo: `flex flex-col items-center justify-center mb-[1rem]`,
  profileName: `text-[0.87rem] lg:text-[1.25rem] text-[#110E2E] font-bold leading-[1.5rem] capitalize`,
  profileBio: `text-[0.62rem] lg:text-base font-medium text-[#82849E] leading-[1.4rem]`,
  profileSocial: ``,
  rectangleBorder: `w-full absolute bottom-0`,
  borderImg: `w-full h-full`,
};

const Founders = () => {
  const founders = [
    {
      name: "Michael Quartey",
      bio: "CEO",
      image: michaelQuartey,
      url: "https://www.linkedin.com/in/michael-quartey-1786624",
    },
    {
      name: "Lukas Bruell",
      bio: "CPO",
      image: lukasBruell,
      url: "https://www.linkedin.com/in/lukasbruell",
    },
  ];

  return (
    <div className={styles.founders}>
      <div className={styles.founderContainer}>
        <div className={styles.heading}>Meet the Founders</div>
        <div className={styles.profileContainer}>
          {founders.map((el, i) => (
            <div className={styles.profileItem} key={i}>
              <div className={styles.profileCtn}>
                <img
                  src={el.image}
                  alt={`${el.name} Profile`}
                  className={styles.profileImage}
                />
                <div className={styles.profileBg}></div>
              </div>
              <div className={styles.profileInfo}>
                <div className={styles.profileName}>{el.name}</div>
                <div className={styles.profileBio}>{el.bio}</div>
              </div>
              <a
                href={el.url}
                className={styles.profileSocial}
                target="_blank"
                rel="noreferrer"
              >
                <img src={linkedinLogo} alt="linkedinLogo" />
              </a>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.rectangleBorder}>
        <img
          src={RectangleWhite}
          alt="RectangleWhite"
          className={styles.borderImg}
        />
      </div>
    </div>
  );
};

export default Founders;
