import React from "react";
import RectangleWhite from "../../../assets/images/RectangleWhite.svg";

import CalendarItem from "./CalendarItem";
import CtaItem from "../../../components/landingpage/CtaItem";

const styles = {
  analytics: `relative w-full h-fit bg-[#F6F8FA] lg:pt-[3rem] flex flex-col overflow-hidden`,
  analyticsContainer: `w-full h-fit max-lg:flex-col flex mb-[2rem] lg:mb-[6rem]`,
  leftCtn: `w-full h-fit p-[1.25rem] lg:pl-[7.5rem] `,
  rightCtn: `w-full h-fit p-[1.25rem] lg:px-[7.5rem] lg:pt-[1.5rem] flex flex-col gap-[1.5rem]`,
  heading: `text-[0.62rem] lg:text-base text-[#2B8AC8] font-semibold tracking-[0.1rem] uppercase`,
  titlePrimary: `text-base lg:text-[2.25rem] font-extrabold lg:leading-[2.7rem] text-[#211E1E] mt-[0.5rem] lg:mt-[2rem] z-20`,
  titleSecondary: `text-base lg:text-[2.25rem] font-extrabold lg:leading-[2.7rem] about-heading-gradient z-20`,
  bio: `w-full text-[0.62rem] lg:text-[1.12rem] font-medium lg:leading-[1.57rem] text-[#82849E] mt-[0.8rem] lg:mt-[1.5rem]`,
  ctaContainer: `relative w-full lg:w-11/12 px-[1.25rem] mx-auto z-40`,
  ctaImage: `w-full h-full`,
  ctaCtn: `absolute bottom-0 w-full h-full flex items-center justify-between pl-[1.25rem] pr-[3.25rem] lg:px-[4.75rem]`,
  ctaTitle: `text-[0.62rem] lg:text-[0.87rem] lg:text-[1.25rem] lg:text-[3rem] font-extrabold text-[#FFF] lg:leading-[3.3rem] z-50`,
  primaryBtn: `w-fit h-fit text-[0.5rem] lg:text-[0.75rem] lg:text-base bg-[#110E2E] rounded-[0.25rem] lg:rounded-[0.75rem] p-[0.25rem] lg:px-[1.25rem] lg:px-[2.5rem] lg:py-[0.88rem]  font-semibold text-[#fff] cursor-pointer z-50`,
  rectangleBorder: `w-full absolute bottom-0`,
  borderImg: `w-full h-full`,
};

const Analytics = () => {
  return (
    <div className={styles.analytics}>
      <div className={styles.analyticsContainer}>
        <div className={styles.leftCtn}>
          <div className={styles.heading}>Analytics</div>
          <div className={styles.titlePrimary}>
            Monthly assurance and transparency
          </div>
          <div className={styles.bio}>
            USDC reserve holdings are fully disclosed on a weekly basis, along
            with associated mint/burn flows. Additionally, a Big Four accounting
            firm provides monthly third-party assurance that the value of USDC
            reserves are greater than the amount of USDC in circulation. The
            reports are prepared according to attestation standards set out by
            the American Institute of Certified Public Accountants (AICPA).
          </div>
        </div>
        <div className={styles.rightCtn}>
          <CalendarItem />
        </div>
      </div>
      <CtaItem />
      <div className={styles.rectangleBorder}>
        <img
          src={RectangleWhite}
          alt="RectangleWhite"
          className={styles.borderImg}
        />
      </div>
    </div>
  );
};

export default Analytics;
