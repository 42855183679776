
export const RE_DIGIT = new RegExp(/^\d+$/);
export const RE_EMAIL = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
export const RE_PASSWORD = new RegExp(
  `^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W)[A-Za-z\\d\\W]{8,}$`
);

export const SCREEN_SMALLEST = 640;
export const SCREEN_MEDIUM = 768;
export const SCREEN_LARGE = 1024;
export const SCREEN_EXTRA_LARGE = 1280;
export const SCREEN_TWO_EXTRA_LARGE = 1536;
export const eczodexAppUrl = 'https://happy-river-0cbe6fc10.4.azurestaticapps.net';
export const azureApiUrl = "https://eczodex-backend-staging.azurewebsites.net";