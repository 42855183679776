import NavbarLanding from '../../components/navbar/NavbarLanding';
import React, { useEffect } from 'react'
import HeroAbout from './components/HeroAbout';
import Footer from '../../components/landingpage/Footer';
import Founders from './components/Founders';
import CoreTeam from './components/CoreTeam';
import AdvisoryTeam from './components/AdvisoryTeam';

import { useLocation } from 'react-router-dom';

const styles = {
    appWrapper: `w-full h-fit overflow-x-hidden`,
  };
  

const About = () => {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

    //scroll to section
    const scrollToSection = (sectionId: string) => {
        if (typeof document !== "undefined") {
          const section = document.getElementById(sectionId);
          if (section) {
            section.scrollIntoView({ behavior: "smooth" });
          }
        }
      };
  
    return (
      <div className={styles.appWrapper}>
        <NavbarLanding scrollToSection={scrollToSection} />
        <HeroAbout/>
        <Founders/>
        <CoreTeam/>
        <AdvisoryTeam/>
        <Footer scrollToSection={scrollToSection}/>
      </div>
    )
}

export default About;