import React from "react";

import RectangleWhite from "../../../assets/images/RectangleWhite.svg";
import CtaItem from "../../../components/landingpage/CtaItem";

const styles = {
  features: `relative w-full h-fit bg-[#FFF]`,
  featuresContainer: `w-full p-[1.25rem] lg:py-[2rem] lg:px-[7.5rem] flex flex-wrap gap-[1rem] lg:gap-[2rem] justify-center items-center mb-[5rem] lg:mb-[8rem]`,
  featureCard: `w-full h-[25rem] lg:w-[36.5rem] lg:h-[40.45rem] p-[1.25rem] lg:px-[2.69rem] lg:py-[3.7rem] flex flex-col justify-end rounded-xl bg-[#F6F8FA]  bg-cover bg-center bg-no-repeat`,
  featureCtn: `flex flex-col gap-[0.75rem] lg:gap-[1.5rem]`,
  featureTitle: `text-base lg:text-[2rem] text-center font-extrabold lg:leading-[2.2rem] text-[#110E2E]`,
  featureBio: `text-[0.87rem] lg:text-[1.12rem] text-center font-normal lg:leading-[1.46rem] text-[#82849E]`,
  rectangleBorder: `w-full absolute bottom-0`,
  borderImg: `w-full h-full`,
};

const Features = () => {
  return (
    <div className={styles.features}>
      <div className={styles.featuresContainer}>
        <div
          className={`${styles.featureCard} bg-[url('./assets/images/liquidity-feature.png')]`}
        >
          <div className={styles.featureCtn}>
            <div className={styles.featureTitle}>Permissionless liquidity </div>
            <div className={styles.featureBio}>
              USDO can be traded permissionlessly across secondary-market
              exchanges without any restrictions.
            </div>
          </div>
        </div>
        <div
          className={`${styles.featureCard} bg-[url('./assets/images/assets-feature.png')]`}
        >
          <div className={styles.featureCtn}>
            <div className={styles.featureTitle}>
              Backed by high quality assets
            </div>
            <div className={styles.featureBio}>
              Collateral assets backing USDO are of the highest quality and held
              with regulated bankruptcy-remote custodians.
            </div>
          </div>
        </div>
        <div
          className={`${styles.featureCard} bg-[url('./assets/images/defi-feature.png')]`}
        >
          <div className={styles.featureCtn}>
            <div className={styles.featureTitle}>Composable with DeFi</div>
            <div className={styles.featureBio}>
              USDO is an ERC-20 token and can be used across DeFi composably
              without any restrictions.
            </div>
          </div>
        </div>
        <div
          className={`${styles.featureCard} bg-[url('./assets/images/accessibility-feature.png')]`}
        >
          <div className={styles.featureCtn}>
            <div className={styles.featureTitle}>Global accessibility</div>
            <div className={styles.featureBio}>
              As a soft-pegged stablecoin, USDO can be traded on the blockchain
              and is thus available 24/7 for cross-border transactions.
            </div>
          </div>
        </div>
      </div>
      <CtaItem />
      <div className={styles.rectangleBorder}>
        <img
          src={RectangleWhite}
          alt="RectangleWhite"
          className={styles.borderImg}
        />
      </div>
    </div>
  );
};

export default Features;
