import React, { useEffect } from "react";


import Hero from "./Hero";
import NavbarLanding from "../navbar/NavbarLanding";
import About from "./About";
import Eczodex from "./Eczodex";
import Approach from "./Approach";
import Footer from "./Footer";
import FAQs from "./FAQs";
import { useLocation } from "react-router-dom";


const styles = {
  appWrapper: `w-full h-fit overflow-x-hidden`,
};

const LandingPage = () => {
  const { hash } = useLocation();

  // Scroll to section
  const scrollToSection = (sectionId: string) => {
    if (typeof document !== "undefined") {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash]);

  return (
    <div className={styles.appWrapper}>
      <NavbarLanding scrollToSection={scrollToSection}/>
      <Hero />
      <div id="about">
        <About />
      </div>
      <Eczodex />
      <Approach />
      <Footer scrollToSection={scrollToSection}/>
    </div>
  );
};

export default LandingPage;
