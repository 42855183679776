import React from "react";

import cambridgeLogo from "../../assets/icons/cambridge.svg";
import techstarsLogo from "../../assets/icons/techstars.svg";
import genesisBlockLogo from "../../assets/icons/genesisblock.svg";
import RectangleWhite from "../../assets/images/RectangleWhite.svg";
import aboutOverlay from "../../assets/images/AboutBGOverlay.png"

const styles = {
  about: `relative w-full h-fit   bg-[#F6F8FA] lg:pt-[3rem] lg:grid lg:grid-cols-2 overflow-hidden`,
  leftCtn: `p-[1.25rem] lg:pl-[7.5rem] `,
  rightCtn: `flex flex-col justify-center lg:justify-end items-end md:mb-[3rem] lg:mb-[4rem]`,
  rightCtnImg: `w-full sm:w-1/2 lg:w-auto h-auto `,
  bgOverlay: `w-full h-full absolute right-0 bottom-6 lg:bottom-10 lg:bottom-20 bg-[url('./assets/images/AboutBGOverlay.png')] bg-contain bg-no-repeat bg-right-bottom`,
  heading: `text-[0.62rem] lg:text-base text-[#2B8AC8] font-semibold tracking-[0.1rem] uppercase`,
  titlePrimary: `text-base lg:text-[2.25rem] font-extrabold lg:leading-[2.7rem] text-[#211E1E] mt-[0.5rem] lg:mt-[2rem] z-20`,
  titleSecondary: `text-base lg:text-[2.25rem] font-extrabold lg:leading-[2.7rem] about-heading-gradient z-20`,
  bio: `w-full text-[0.62rem] lg:text-[1.12rem] font-medium lg:leading-[1.57rem] text-[#82849E] mt-[0.8rem] lg:mt-[1.5rem]`,
  creditContainer: `flex flex-wrap gap-[1rem] md:gap-[4rem] mt-[0.5rem] lg:mt-[4.25rem] mb-[3.75rem] lg:mb-[6.75rem]`,
  creditItem: `flex flex-col gap-[0.5rem] lg:gap-[1.05rem]`,
  creditTitle: `text-[#82849E] text-[0.5rem] lg:text-[0.87rem] font-medium tracking-[0.087rem] uppercase`,
  creditLogoCtn: `w-[7.9rem] h-[1.6rem]`,
  creditLogoCtnSec: `w-[7.9rem] h-[1.6rem] mt-auto`,
  rectangleBorder: `w-full absolute bottom-0`,
  borderImg: `w-full h-full`,
};

const About = () => {
  return (
    <div className={styles.about}>
      <div className={styles.leftCtn}>
        <div className={styles.heading}>About</div>
        <div className={styles.titlePrimary}>
          The most capital efficient <br />
          <span className={styles.titleSecondary}>
            on-ramp to the digital economy
          </span>
        </div>
        <div className={styles.bio}>
          Our regulated stablecoin permits customers accessing the digital asset
          markets to retain ownership of their collateral at issuance. For cash
          collateral, users can continue to earn interest payments on cash
          reserves held in segregated customer bank accounts.
        </div>
        <div className={styles.creditContainer}>
          <div className={styles.creditItem}>
            <div className={styles.creditTitle}>FOUNDED AT</div>
            <img
              src={cambridgeLogo}
              alt="cambridgeLogo"
              className={styles.creditLogoCtn}
            />
          </div>
          <div className={styles.creditItem}>
            <div className={styles.creditTitle}>BACKED BY</div>
            <div className="flex gap-[0.5rem] lg:gap-[1.05rem]">
            <img
              src={techstarsLogo}
              alt="techstarsLogo"
              className={styles.creditLogoCtn}
            />
                        <img
              src={genesisBlockLogo}
              alt="genesisBlockLogo"
              className={styles.creditLogoCtnSec}
            />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.rightCtn}>
        <img src={aboutOverlay} alt="aboutOverlay" className={styles.rightCtnImg}/>
      </div>
      <div className={styles.rectangleBorder}>
        <img
          src={RectangleWhite}
          alt="RectangleWhite"
          className={styles.borderImg}
        />
      </div>
    </div>
  );
};

export default About;
