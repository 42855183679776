import React from "react";

import davidCampbell from "../../../assets/profiles/DavidCampbell.png";
import deonQWu from "../../../assets/profiles/DeonQWu.png";
import brianCoffey from "../../../assets/profiles/BrianCoffey.png";
import philipBrice from "../../../assets/profiles/PhilipBrice.png";
import linkedinLogo from "../../../assets/profiles/linkedin.svg";
import RectangleGrey from "../../../assets/images/RectangleGrey.svg";

const styles = {
  coreTeam: `relative w-full h-fit min-h-[42rem] px-[1.25rem] bg-[#fff] flex flex-col items-center justify-start overflow-hidden`,
  coreTeamContainer: `w-fit flex flex-col items-center pt-[3rem] gap-[3.5rem]`,
  heading: `text-[0.62rem] lg:text-base text-[#2B8AC8] font-semibold tracking-[0.1rem] uppercase`,
  profileContainer: `flex flex-wrap items-center justify-center gap-[1.69rem] mb-20 lg:mb-[5.62rem]`,
  profileItem: `w-[15.8rem] lg:w-[17.8rem] flex flex-col items-center justify-center`,
  profileCtn: `relative w-[8.45rem] lg:w-[10.45rem] h-[8.45rem] lg:h-[10.45rem]  overflow-hidden mb-[1.5rem] rounded-[50%]`,
  profileBg: `w-full h-full absolute top-0 left-0 z-0 bg-[url('./assets/profiles/profilebg.png')] bg-contain bg-no-repeat`,
  profileImage: `w-full h-full absolute top-0 left-0 z-10`,
  profileInfo: `flex flex-col items-center justify-center mb-[1rem]`,
  profileName: `text-[0.87rem] lg:text-[1.25rem] text-[#110E2E] font-bold leading-[1.5rem] capitalize`,
  profileBio: `text-[0.62rem] lg:text-base font-medium text-[#82849E] leading-[1.4rem]`,
  profileSocial: ``,
  rectangleBorder: `w-full absolute bottom-0`,
  borderImg: `w-full h-full`,
};

const CoreTeam = () => {
  const coreteam = [
    {
      name: "David Campbell",
      bio: "Role",
      image: davidCampbell,
      url: "https://www.linkedin.com/in/david-campbell-5286b26"
    },
    {
      name: "Deon Q Wu",
      bio: "Role",
      image: deonQWu,
      url: "https://www.linkedin.com/in/deon-w-8b89681a6"
    },
    {
      name: "Brian Coffey",
      bio: "Role",
      image: brianCoffey,
      url: "https://www.linkedin.com/in/brian-coffey-6bb29110"
    },
    {
      name: "Philip Brice",
      bio: "Role",
      image: philipBrice,
      url: "https://www.linkedin.com/in/philip-brice-7b1512a1"
    },
  ];

  return (
    <div className={styles.coreTeam}>
      <div className={styles.coreTeamContainer}>
        <div className={styles.heading}>Core Team</div>
        <div className={styles.profileContainer}>
          {coreteam.map((el, i) => (
            <div className={styles.profileItem} key={i}>
              <div className={styles.profileCtn}>
                <img
                  src={el.image}
                  alt={`${el.name} Profile`}
                  className={styles.profileImage}
                />
                <div className={styles.profileBg}></div>
              </div>
              <div className={styles.profileInfo}>
                <div className={styles.profileName}>{el.name}</div>
                <div className={styles.profileBio}>{el.bio}</div>
              </div>
              <a
                href={el.url}
                className={styles.profileSocial}
                target="_blank"
                rel="noreferrer"
              >
                <img src={linkedinLogo} alt="linkedinLogo" />
              </a>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.rectangleBorder}>
        <img
          src={RectangleGrey}
          alt="RectangleGrey"
          className={styles.borderImg}
        />
      </div>
    </div>
  );
};

export default CoreTeam;
