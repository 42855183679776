import React from "react";


import RectangleWhite from "../../assets/images/RectangleWhite.svg";


const styles = {
  approach: `relative w-full h-fit bg-[#110E2E] lg:pt-[3rem] overflow-hidden`,
  leftctn: `w-full lg:w-1/2 px-[1.25rem] lg:pl-[7.5rem]`,
  heading: `text-[0.62rem] lg:text-base text-[#2B8AC8] font-semibold tracking-[0.1rem] uppercase`,
  titlePrimary: `text-base lg:text-[2.25rem] font-extrabold lg:leading-[2.7rem] text-[#fff] mt-[0.5rem] lg:mt-[2rem] z-20`,
  titleSecondary: `text-base lg:text-[2.25rem] font-extrabold lg:leading-[2.7rem] about-heading-gradient z-20`,
  bio: `w-full text-[0.62rem] lg:text-[1.12rem] font-medium lg:leading-[1.57rem] text-[#82849E] mt-[0.8rem] lg:mt-[1.5rem]`,
  rightCtn: `w-full px-[1.5rem] lg:w-1/2 lg:pb-[4.88rem] flex flex-col gap-[1.5rem] lg:gap-[3rem] mb-[8rem] lg:mb-[16rem]`,
  cardItem: `w-fit h-fit py-[1.5rem] lg:p-[1.5rem] flex flex-col gap-[1rem] lg:bg-[#82849E] lg:bg-opacity-5 rounded-[0.75rem]`,
  cardTitle: `text-base lg:text-[1.25rem] font-bold lg:leading-[1.5rem] text-[#fff]`,
  cardBio: `text-[0.62rem] lg:text-[1.12rem] font-medium lg:leading-[1.575rem] text-[#82849E]`,
  listName: `text-base lg:pl-[1.5rem] lg:text-[1.25rem] font-bold lg:leading-[1.5rem] text-[#fff] text-opacity-50`,
  rectangleBorder: `w-full absolute bottom-0`,
  borderImg: `w-full h-full`,
};

const Approach = () => {
  return (
    <div className={styles.approach}>
      <div className="max-lg:flex-col flex gap-[1rem]">
      <div className={styles.leftctn}>
        <div className={styles.heading}>Our customer first approach</div>
        <div className={styles.titlePrimary}>
          Regulated Institutional Partners.
          Established Customer Protection.
          Bankruptcy-remote collateral.
        </div>
        <div className={styles.bio}>
          Every activity from the custody of collateral assets to the issuance
          of the USDO stablecoin is conducted with the highest customer
          protection. Our focus is on regulatory protection before anything
          else.
        </div>
      </div>
      <div className={styles.rightCtn}>
        <div className={styles.cardItem}>
          <div className={styles.cardTitle}>
            US licensed Money Service Business (MSB) <br /> Licence Partnership
          </div>
          <div className={styles.cardBio}>
          USDO is issued by a regulated US MSB
          </div>
        </div>
        <div className={styles.listName}>Highly reputable custodians</div>
        <div className={styles.listName}>Audited Code</div>
        <div className={styles.listName}>Regulated Service Partners</div>
      </div>
      </div>
      <div className={styles.rectangleBorder}>
        <img
          src={RectangleWhite}
          alt="RectangleWhite"
          className={styles.borderImg}
        />
      </div>
    </div>
  );
};

export default Approach;
